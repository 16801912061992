import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { switchMap } from 'rxjs/operators';
import { StripeService, StripeCardNumberComponent } from 'ngx-stripe';
import { CheckoutService } from 'src/app/services/checkout.service';
import { StripeCardElementOptions, StripeElementsOptions } from '@stripe/stripe-js';
import { Router } from '@angular/router';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { ProductSelectionBottomSheet } from 'src/app/components/checkout/product-selection-bottomsheet/product-selection-bottomsheet.component';
import { SubscriptionPlan } from 'src/app/models/subscriptionplan';
import { user } from 'firebase-functions/v1/auth';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss']
})
export class PaymentComponent implements OnInit {

  @ViewChild(StripeCardNumberComponent) card: StripeCardNumberComponent;

  checkoutForm : UntypedFormGroup;
  selectedPaymentMethod:string = 'card';
  loading: boolean = false;
  subscriptionPlans:Array<any> = [];
  selectedSubscriptionPlan: any;
  errorMessage:string;
  errorMessageCoupon:string;
  couponCode:string;
  coupon:any;
  grandTotal:number = 10;
  memberNr:string;

  cardOptions: StripeCardElementOptions = {
    style: {
      base: {
        iconColor: '#666EE8',
        color: '#31325F',
        fontWeight: '300',
        fontFamily: 'Roboto, "Helvetica Neue", Helvetica, sans-serif',
        fontSize: '18px',
        '::placeholder': {
          color: '#CFD7E0',
        },
      },
    },
  };

  elementsOptions: StripeElementsOptions = {
    locale: 'de',
  };

  constructor(      
    public authService: AuthService,    
    private fb: UntypedFormBuilder,
    private checkoutService: CheckoutService,
    private stripeService: StripeService,
    private router: Router,
    private _bottomSheet: MatBottomSheet,
    private elementRef:ElementRef,
    private cd: ChangeDetectorRef,
    private analytics: AngularFireAnalytics,
    ) {}

  ngOnInit(): void {
    this.checkoutForm = this.fb.group({
      productId: ['small-charge-EUR', Validators.required],
      amount: [1001, [Validators.required, Validators.pattern(/\d+/)]],
      legal: [Validators.required],
    });
    this.getSubscriptionsPlans();
  }
  
  async getSubscriptionsPlans() {
    const user = await this.authService.getUser();

    user.subscribe((user) => {
      this.memberNr = user.membershipNumber;
      // if(user?.subscription) {
      //   this.router.navigate(["/start"]);
      //   return
      // }

      this.checkoutService.getSubscriptionPlans().subscribe(result => {
        if(result) {

          this.subscriptionPlans = result.data;

  

          let userSubscriptionPlan = user?.tempSelectedProduct;
          let localStorageSubscriptionPlan = localStorage.getItem('selectedSubscriptionPlanId')
          // console.log(userSubscriptionPlan);
          // console.log(localStorageSubscriptionPlan);

          if(userSubscriptionPlan && userSubscriptionPlan != "undefined") {
            // console.log("if");

            this.selectedSubscriptionPlan = {
              id: userSubscriptionPlan,
              data: result.data[userSubscriptionPlan]
            };
            this.grandTotal = result.data[userSubscriptionPlan].prices[userSubscriptionPlan + "-EUR-Monthly"].price/100;

          } else if (localStorageSubscriptionPlan) { 
            // console.log("elseif");

            this.selectedSubscriptionPlan = {
              id: localStorageSubscriptionPlan,
              data: result.data[localStorageSubscriptionPlan]
            };
            this.grandTotal = result.data[localStorageSubscriptionPlan].prices[localStorageSubscriptionPlan + "-EUR-Monthly"].price/100;
          } else {
            // console.log("else");

            this.selectedSubscriptionPlan = {
              id: "couple",
              data: result.data["couple"]
            };
            this.grandTotal = result.data["couple"].prices["couple-EUR-Monthly"].price/100;
            localStorage.setItem('selectedSubscriptionPlanId',"couple");
          }
          // console.log(this.selectedSubscriptionPlan);

          let localStorageCouponCode = localStorage.getItem('couponcode')
          if(localStorageCouponCode) {
            this.getCoupon(localStorageCouponCode);
          }

          this.loading = false;
        }
      });
    });
  }

  changeProduct() {
    this._bottomSheet.open(ProductSelectionBottomSheet, {
      data: { subscriptionPlans: this.subscriptionPlans, selectedSubscriptionPlanId: this.selectedSubscriptionPlan.id }
    }).afterDismissed().subscribe((data) => {
      if(data != undefined) {
        this.removeCoupon();
        this.selectedSubscriptionPlan = {
          id: data,
          data: this.subscriptionPlans[data]
        };
        localStorage.setItem('selectedSubscriptionPlanId',data);
      }
    });
  }

  getCoupon(couponCode:string) {
    this.errorMessageCoupon = "";
    this.loading = true;
    this.checkoutService.getCoupon(couponCode).then((result) => {
      this.coupon = result.data;
      let planPrice = this.selectedSubscriptionPlan.data.prices[this.selectedSubscriptionPlan.id + "-EUR-Monthly"].price/100;
      let reductionPrice = planPrice * result.data.discount_percentage/100;
      this.grandTotal = planPrice - reductionPrice;

      console.log(planPrice);
      console.log(this.grandTotal);
      console.log(this.coupon);

      // CHECK FOR VALID SELECTED PLAN
      let constrainPlan = this.coupon.item_constraints.find(item => item.item_type === 'plan');


      if(constrainPlan) {


        constrainPlan.item_price_ids.forEach(element => {

          let validSubscriptionPlanId = element.substring(0, element.indexOf("-"));

          // console.log(element.substring(0, element.indexOf("-")));
          // console.log(this.selectedSubscriptionPlan);

            if(validSubscriptionPlanId === this.selectedSubscriptionPlan.id) {
            } else {

              this.selectedSubscriptionPlan = {
                id: validSubscriptionPlanId,
                data: this.subscriptionPlans[validSubscriptionPlanId]
              };

              planPrice = this.selectedSubscriptionPlan.data.prices[this.selectedSubscriptionPlan.id + "-EUR-Monthly"].price/100;
              reductionPrice = planPrice * result.data.discount_percentage/100;
              this.grandTotal = planPrice - reductionPrice;
        
              console.log(planPrice);
              console.log(this.grandTotal);
              console.log(this.coupon);

              // this.grandTotal = this.subscriptionPlans[data].prices[data + "-EUR-Monthly"].price/100;
              localStorage.setItem('selectedSubscriptionPlanId',validSubscriptionPlanId);
            }

        });
      }

      this.loading = false;

      }, error => {
        // console.log(error.details.errorCode);
      
        switch (error.details.errorCode) {
  
          case "ResourceNotFound":
            this.errorMessageCoupon = "Der Coupon-Code konnte nicht gefunden werden.";
            // console.log(this.errorMessageCoupon);
            break;
  
          default:
            this.errorMessageCoupon = "Coupon konnte nicht gefunden werden.";
            break;
        } 
        this.loading = false;
  
        
      });




  } 

  removeCoupon() {
    this.coupon = null; 
    this.couponCode = '';
    this.grandTotal = this.selectedSubscriptionPlan.data.prices[this.selectedSubscriptionPlan.id + "-EUR-Monthly"].price/100;
  }

  selectPaymentMethod(paymentMethod: string): void {
    this.errorMessage = "";
    this.selectedPaymentMethod = paymentMethod;    
    this.cd.detectChanges();
  }

  validateForm(): boolean {
    // this.errorMessage = null;
    if (this.checkoutForm.valid ) {
        return true;
    }
    return false;
  }





  checkoutWithCard() {

    if (!this.validateForm()) {
      return null;
    }
    this.loading = true;
    this.checkoutService.createCardPaymentMethod({type: 'card',card: this.card.element})
    .subscribe(paymentMethodResponse => {
      if(paymentMethodResponse?.error) {
        this.errorMessage = paymentMethodResponse.error.message;
        this.loading = false;
        return null;
      }
      this.errorMessage = "";
      this.checkoutService.createCardPaymentIntent(this.selectedSubscriptionPlan?.id + "-EUR-Monthly",paymentMethodResponse.paymentMethod.id)
        .pipe(
          switchMap((pi) => 
            this.checkoutService.confirmCardPayment(this.selectedSubscriptionPlan?.id + "-EUR-Monthly",pi,this.couponCode).then((result) => {
              if(result?.error) {
                this.errorMessage = result.error.message;
                this.loading = false;
              } else {
                this.analytics.logEvent('payment_card');
                sessionStorage.setItem('orderNr',this.memberNr);
                sessionStorage.setItem('orderAmount',this.grandTotal.toString());
                this.router.navigate(["/subscription/finish"]);
              }

            })
          )
        )
        .subscribe((result) => {
        //   // if (result.error) {
        //   //   // Show error to your customer (e.g., insufficient funds)
            // console.log(result);
        //   // } else {
        //   //   // The payment has been processed!
        //   //   if (result.paymentIntent.status === 'succeeded') {
        //   //     // Show a success message to your customer
        //   //   }
        //   // }
        });
    });
  }



  


  checkoutWithPaypal() {
    this.loading = true;
    sessionStorage.setItem('orderNr',this.memberNr);
    sessionStorage.setItem('orderAmount',this.grandTotal.toString());
    this.checkoutService.createPayPalBillingAgreement(this.selectedSubscriptionPlan.id + "-EUR-Monthly", "createSubscription", this.couponCode).then(result => {
      this.analytics.logEvent('payment_paypal');
      const link = result.links.find(item => item.rel === 'approval_url');
      window.location.href = link.href;
    });
  }

  checkoutWithCoupon() {
    this.loading = true;
    this.checkoutService.checkoutSubscriptionWithCoupon(this.selectedSubscriptionPlan.id + "-EUR-Monthly",this.couponCode).then(result => {
      if(result?.error) {
        this.errorMessage = result.error.message;
        this.loading = false;
      } else {
        this.analytics.logEvent('payment_coupon');
        sessionStorage.setItem('orderNr',this.memberNr);
        sessionStorage.setItem('orderAmount',this.grandTotal.toString());
        this.router.navigate(["/subscription/finish"]);
      }
    });
  }

  ngAfterViewInit() {
    var s = document.createElement("script");
    s.type = "text/javascript";
    s.src = "https://widgets.trustedshops.com/js/XEC6BBD013906218857FBDC4763B7D908.js";
    this.elementRef.nativeElement.appendChild(s);
  }

}